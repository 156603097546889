export default function(props) {
    return(
        <div class="jumbotron jumbotron-fluid mt-5">
        <div class="container">
          <h1 class="display-4">More languages Comming Soon!</h1>
          <p class="lead">Japanese, Korean, and Spanish planned for v2</p>
          <p class="lead">
            {/* <a class="btn btn-dark btn-lg" href="https://store.line.me/stickershop/product/20019319/en" role="button">Learn more</a> */}
          </p>
          </div>
        </div>
    )
}